import React, { useContext, useEffect, useRef, createRef } from 'react'
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Footer from '../components/Footer'
import {
  Box,
  Flex,
  Container,
  SimpleGrid,
} from '@chakra-ui/react'
import themeAlopex, { spacing, breakpoints, colors, hexToRGB, lumVal, typography } from "../theme/themeAlopex"
import { H1, H2, H3, H4 } from '../theme/Headings'
import ColorContext from '../context/ColorContext'
// SVG Components
import {gsap} from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
// Register gsap plugins
gsap.registerPlugin(ScrollTrigger)

const  Nomad = ({data}) => {
  const { color } = useContext(ColorContext)
  const refVid = useRef(null)

  useEffect(() => {
    if (refVid.current) {
      // Listen for end of video and reload for persistent video poster (thumbnail)
      refVid.current.addEventListener('ended', () => {
        refVid.current.load()
      })
      // Show video controls on hover
      refVid.current.controls = false
      refVid.current.addEventListener('mouseover', () => { refVid.current.controls = 'controls' })
      refVid.current.addEventListener('mouseout', () => { refVid.current.controls = false })
    }
  }, [])

  return (
    <Layout>
      <SEO title="Nomad Cinematics + Alopex" description="Nomad and Alopex work to complement each others efforts." />
      <Box>
        {/* HEADER */}
        <Box
          background="url(/images/nomad/alaska-based-cinematographers-optimized.webp)"
          backgroundSize="cover"
          backgroundPosition="bottom center"
          marginTop="45px"
          position="relative"
          sx={{
            '.nomadLogo': {
              margin: '0 auto 36px'
            },
            p: {
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto 12px',
              color: '#00021A'
            }
          }}
        >
          <Box
            className="fadeToBlack"
            position="absolute"
            width="100%"
            height="50%"
            bottom="0"
            left="0"
            background="linear-gradient(180deg, transparent, #00021A)"
          />
          
          {/* Logo & Blurb */}
          <Container maxWidth={breakpoints.xl} padding={[`${spacing.header} 1rem ${spacing.groups}`, null, `${spacing.section} 1rem ${spacing.section}`]}>
            <img className="nomadLogo" src="/images/nomad/award-winning-alaska-film-maker.png" alt="Nomad Cinematics Logo" style={{maxWidth: '600px', width: '100%'}} />
            <p>Alopex builds exceptional experiences, Nomad tells exceptional stories.</p>
            <p>Video provides the opportunity for brands to reach audiences with subtlety and finesse. It is a demanding trade, requiring incredible amounts of planning, vision, creativity and skill.</p>
            <p>Nomad are experts in their craft.</p>
          </Container>

          {/* Video */}
          <Container maxWidth={breakpoints.xl} padding={[`0 1rem ${spacing.groups}`, null, `0 1rem ${spacing.section}`]} position="relative">
            <Box
              position='relative'
              pb="56.25%"
              height="0"
              overflow="hidden"
              boxShadow={`
                0.7px 0.7px 0.9px -3px rgba(0, 0, 0, 0.034),
                1.7px 1.7px 2.1px -3px rgba(0, 0, 0, 0.048),
                3.1px 3.1px 4px -3px rgba(0, 0, 0, 0.06),
                5.6px 5.6px 7.1px -3px rgba(0, 0, 0, 0.072),
                10.4px 10.4px 13.4px -3px rgba(0, 0, 0, 0.086),
                25px 25px 32px -3px rgba(0, 0, 0, 0.12)
              `}
              backgroundColor="white"
              sx={{
                'iframe, object, embed': {
                  position: 'absolute',
                  inset: '0'
                },
              }}
            >
              <object style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Video Here
              </object>
            </Box>
          </Container>
        </Box>

        {/* QUOTES & AWARDS */}
        {/* Quote */}
        <Box backgroundColor="#00021A">
          <Box
            lineHeight="1.5"
            fontSize="2rem"
            color="#607782"
            fontFamily={typography.body}
            textAlign="center"
            maxWidth="900px"
            margin={[`0 auto ${spacing.groups}`, null, `0 auto ${spacing.section}`]}
            padding="0 1rem"
          >
            Nomad is the best and this is something that was written about, which is super awesome and so sexy. Gonna be so rich.
            <Box as="footer" color="#4cb1cd" fontSize="0.75rem" mt={spacing.icon}>-Kerry Youngren, CTA MTA</Box>
          </Box>
          {/* Awards */}
          <Flex
            justifyContent="center"
            gridGap={spacing.icon}
            flexWrap="wrap"
            p={[`0 1rem ${spacing.groups}`, null, `0 1rem ${spacing.section}`]}
            maxWidth={breakpoints['2xl']}
            margin="0 auto"
            sx={{
              img: {minWidth: '100px'}
            }}
          >
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
            <img src="/images/nomad/award.png" alt="Film Award"/>
          </Flex>
        </Box>

        {/* INTRODUCTION */}
        <Box background="linear-gradient(135deg, #808c93, #cbc9c9)">
          <Container
            maxWidth={breakpoints.xl}
            py={[spacing.groups, null, spacing.section]}
          >
            <Box
              sx={{
                '.aloNomad': {
                  maxWidth: '207px',
                  marginBottom: spacing.icon
                },
                h1: {
                  fontSize: '36px',
                  lineHeight: '1.36',
                  maxWidth: '700px'
                },
                h2: {
                  fontSize: '36px'
                }
              }}
            >
              <img className="aloNomad" src="/images/nomad/alaska-video-production.png" alt="Alopex + Nomad"/>
              <H1>There are measurable benefits to having your marketing and video team working in tandem. We work to complement eachothers' efforts.</H1>
              <SimpleGrid columns={[1, null, 2]} gridGap="50px">
                <Box>
                  <H2>Perspective</H2>
                  <p>Video and audio for marketing requires a deep understanding of a client's brand language. Alopex works with clients to develop this voice. Nomad and Alopex work closely to ensure that your content is original and profit-making.</p>
                </Box>
                <Box>
                  <H2>Strategy</H2>
                  <p>Our combined creative team works to develop strategy for video content and hone the conept before Nomad starts filming. That equates to a lower cost of production, and better content management.</p>
                </Box>
                <Box>
                  <H2>Placement</H2>
                  <p>With the backing of Alopex, Nomad's content goes further. Alopex is Google certified in Google Ads and places across popular channels, such as Spotify, Youtube, Facebook, Instagram, TikTok, Snapchat, and other major channels.</p>
                </Box>
                <Box>
                  <H2>Reporting</H2>
                  <p>We believe in data. During our monthly marketing reports, we show you exactly how your content is performing and who is engaging with it. This allows us to make informed decisions about communicating your brand for the best result.</p>
                </Box>
              </SimpleGrid>
            </Box>
          </Container>
        </Box>
        {/* VIDEO SPOT */}
        <Box
          background="url(/images/nomad/best-videographer-alaska.webp)"
          backgroundSize="cover"
          backgroundPosition="center"
        >
          <Container maxWidth={breakpoints.xl} p={[`${spacing.groups} 1rem`, null, `${spacing.section} 1rem`]}>
            <Box
              position="relative"
              pb="56.25%"
              height="0"
              boxShadow={`
                0.7px 0.7px 0.9px -3px rgba(0, 0, 0, 0.034),
                1.7px 1.7px 2.1px -3px rgba(0, 0, 0, 0.048),
                3.1px 3.1px 4px -3px rgba(0, 0, 0, 0.06),
                5.6px 5.6px 7.1px -3px rgba(0, 0, 0, 0.072),
                10.4px 10.4px 13.4px -3px rgba(0, 0, 0, 0.086),
                25px 25px 32px -3px rgba(0, 0, 0, 0.12)
              `}
              backgroundColor="white"
              sx={{
                'iframe, object, embed': {
                  position: 'absolute',
                  inset: '0'
                },
              }}
            >
              <object style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Video Here
              </object>
            </Box>
            <Box
              position="relative"
              width="100%"
              maxWidth="600px"
              backgroundColor="#00021A"
              color="white"
              marginTop={[null, null, '-10px']}
              marginLeft={[null, null, '20px']}
              padding="1rem"
              sx={{
                h3: {fontSize: '24px'},
                p: {lineHeight: '1.375'}
              }}
            >
              <H3>MTA Connected Spot</H3>
              <p style={{marginBottom: 0}}>Here is a short description of the project, including some notable things about the timeline and the budget. Maybe even the purpose of the actual video and how we put it together.</p>
            </Box>
          </Container>
        </Box>
      </Box>
    </Layout>
  ) 
}

export const query = graphql`
  query nomPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Nomad